import {UsersPayload} from '../models/response/UsersPayload'
import identityClient from '../identityClient'
import {SingleUser} from '../models/response/UserPayload'
import {ID} from '../../../_metronic/helpers'
import {SuspendUserPayload} from '../models/payload/SuspendUserPayload'

export function getById(userId: string) {
  return identityClient.get<SingleUser>(`/Users/${userId}`, {
    headers: {accept: 'application/json'},
  })
}

export function editUser(userId: string, payload: any) {
  return identityClient.put<SingleUser>(`/Users/${userId}`, payload)
}

export function getUsers(
  pageNumber: number,
  pageSize: number,
  channel: number,
  start?: any,
  end?: any
) {
  const path = start
    ? `/Users?Page=${pageNumber}&PerPage=${pageSize}&channel=${channel}&CreateDateStart=${start}&CreateDateEnd=${end}`
    : `/Users?Page=${pageNumber}&PerPage=${pageSize}&channel=${channel}`
  return identityClient.get<UsersPayload[]>(path, {
    headers: {accept: 'application/json'},
  })
}

export function deleteUserById(userId: string) {
  return identityClient.delete(`/Users/${userId}`, {
    headers: {accept: 'application/json'},
  })
}

export function blockUserById(userId: string) {
  return identityClient.put(`/Users/${userId}/Block`, {
    headers: {accept: 'application/json'},
  })
}

export function suspendUserById(userId: string, payload: SuspendUserPayload) {
  return identityClient.put(`/Users/${userId}/Suspend`, payload, {
    headers: {accept: 'application/json'},
  })
}

export function antiFraudToolsById(userId: string | number, parameter: string) {
  return identityClient.get(`/Users/${userId}/AntiFraudTools?Parameter=${parameter}`, {
    headers: {accept: 'application/json'},
  })
}

//double
export function getUsers2(query: string) {
  return identityClient.get(`/Users?${query}`, {
    headers: {accept: 'application/json'},
  })
}

export const deleteSelectedUsers = (userIds: Array<ID>): any => {
  //   const requests = userIds.map((id) => identityClient.delete(`user/${id}`))
  //  return identityClient.all(requests).then(() => {})
  return
}
